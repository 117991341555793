import React from 'react'
function Privacy() {
    return (
        <div>
            <section className="ptb-50 ptb-xs-30 gray-bg">
                <div className="container">
                    <div className="row testimonial">
                        <div className="col-md-12">
                            <p className="MsoNormal" style={{ textAlign: 'center', marginTop: "8em", }} align="center"><b style={{ msoBidiFontWeight: 'normal' }}><span style={{ fontSize: '28.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', }}>Privacy Policy</span></b></p>
                            <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We insist on the highest standards for secure transactions and customer information privacy since we value the trust you place in us.</span></p>
                            <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto', textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Our privacy policy is subject to change at any time without prior notice. To make sure you are aware of any changes, please review this policy periodically.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto', textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>By visiting this website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our website.</span></p>
                            <p className="MsoListParagraphCxSpLast" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto', textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.</span></p>
                            <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Collection of Information</span></u></b></p><br></br>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>When you use our website, we collect and store your personal information which is provided by you from time to time. We aim to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}> In general, you can browse the Website without revealing your identity or any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Wherever possible, we indicate which fields are optional and which are required. You always have the option to not provide information by choosing not to use a particular service or feature on the Website. We may automatically track certain information about you based upon your behaviour on our Website. We use this information to do internal research on our user's demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our website or not), which URL you next go to (whether this URL is on our website or not), your computer browser information, and your IP address.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We use data collection devices such as "cookies" on certain pages of the Website to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small identifiers sent from a web server and stored on your computer's hard drive, that help us to recognize you if you visit our website again.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}> Additionally, you may encounter "cookies" or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We collect information about your buying behaviour if you choose to buy on the Website.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>In case you transact with us, we collect certain additional information, such as a billing address, credit / debit card number, expiration date, other payment details and tracking information from money orders and cheques.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}> In case you choose to post messages on our chat rooms, message boards or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>In case you send us personal correspondence, such as
                                emails or letters, or if other users or third parties send us correspondence
                                about your activities or postings on the Website, we may collect such
                                information into a file specific to you.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We collect personally identifiable information (email
                                address, name, phone number, credit card / debit card / other payment
                                instrument details, etc.) when you set<span style={{ msoSpacerun: 'yes' }}>&nbsp;
                                </span>up a free account with us.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We require your camera/photo gallery access to collect information such as
                                your business visiting card picture so that we can verify your given details &
                                activate your account after proper verification. In our B2B portal some details
                                are highly confidential that is why we cannot activate your account without
                                proper verification.
                            </span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>In case you transact with us, we require your camera/gallery access to collect
                                certain additional information, such as payment screenshot or other payment
                                details and information to fulfil the placed order.
                            </span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Use and
                                Sharing of Information</span></u></b></p>
                            <br></br>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}> At no time will we sell your personally-identifiable data without your permission unless set forth in this Privacy Policy. The information we receive about you or from you may be used by us or shared by us with our corporate affiliates, dealers, agents, vendors and other third parties to help process your request; to comply with any law, regulation, audit or court order; to help improve our website or the products or services we offer; for research; to better understand our customer's needs; to develop new offerings; and to alert you to new products and services (of us or our business associates) in which you may be interested. We may also combine information you provide us with information about you that is available to us internally or from other sources in order to better serve you.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We do not share, sell, trade or rent your personal information to third parties for unknown reasons..</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Cookies</span></u></b></p>
                            <br></br>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>"Cookies" are small identifiers sent from a
                                web server and stored on your computer's hard drive, that help us to recognize
                                you if you visit our website again.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>From time to time, we may place "cookies" on
                                your personal computer. Also, our site uses cookies to track how you found our
                                site. To protect your privacy we do not use cookies to store or transmit any
                                personal information about you on the Internet. You have the ability to accept
                                or decline cookies. Most web browsers automatically accept cookies, but you can
                                usually modify your browser setting to decline cookies if you prefer. If you
                                choose to decline cookies certain features of the site may not function
                                properly or at all as a result.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Links</span></u></b></p>
                            <br></br>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Our website contains links to other sites. Such other
                                sites may use information about your visit to this website. Our Privacy Policy
                                does not apply to practices of such sites that we do not own or control or to
                                people we do not employ. Therefore, we are not responsible for the privacy
                                practices or the accuracy or the integrity of the content included on such
                                other sites. We encourage you to read the individual privacy statements of such
                                websites.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Security</span></u></b></p>
                            <br></br>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We safeguard your privacy using known security
                                standards and procedures and comply with applicable privacy laws. Our websites
                                combine industry-approved physical, electronic and procedural safeguards to
                                ensure that your information is well protected throughout its life cycle in our
                                infrastructure.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Sensitive data is hashed or encrypted when it is
                                stored in our infrastructure. Sensitive data is decrypted, processed and
                                immediately re-encrypted or discarded when no longer necessary. We host web
                                services in audited data centers, with restricted access to the data processing
                                servers. Controlled access, recorded and live-monitored video feeds, 24/7
                                staffed security and biometrics provided in such data centers ensure that we
                                provide secure hosting.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Opt- Out
                                Policy</span></u></b></p>
                            <br></br>
                            <p className="MsoListParagraphCxSpLast" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>All our users have the opportunity to opt-out of receiving
                                non-essential (promotional, marketing-related) communications.</span></p>
                            <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}> Please email us at motoconceptjpr@gmail.com if you no longer wish to receive any information from us.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Consent</span></u></b></p>
                            <br></br>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>By using the Website and/or by providing your
                                information, you consent to the collection and use of the information you
                                disclose on the Website in accordance with this Privacy Policy, including but
                                not limited to your consent for sharing your information as per this privacy
                                policy.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>If we decide to change our privacy policy, we will
                                post those changes on this page so that you are always aware of what
                                information we collect, how we use it, and under what circumstances we disclose
                                it.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Changes to
                                this Privacy Policy</span></u></b></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Our privacy policy is subject to change at any time
                                without notice. We may change our Privacy Policy from time to time. Please
                                review this policy periodically to make sure you are aware of any changes.</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><b style={{ msoBidiFontWeight: 'normal' }}><u><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Questions</span></u></b></p>
                            <p className="MsoListParagraphCxSpMiddle" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></p>
                            <p className="MsoListParagraphCxSpLast" style={{ marginBottom: '.0001pt', msoAddSpace: 'auto' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>If you have any questions about our Privacy Policy, please e-mail your questions to us at motoconceptjpr@gmail.com</span></p><p />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Privacy
