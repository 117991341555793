import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "./Slider";
import { Link } from "react-router-dom";
import ProductSlider from "./ProductSlider";
import { baseUrl } from "./baseUrl";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function Home(props) {
  const [mostpopularbrands, setMostpopularbrands] = useState([]);
  const [mostpopularproducts, setMostpopularproducts] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [TwoImg, settwo] = useState([]);
  const [SixImg, setsix] = useState([]);
  const [FeaturedProducts, setFeaturedProducts] = useState([]);
  const [data, setData] = useState([]);
  const getFeaturedProducts = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/featured_products"
      );
      console.log("featured products", response);
      if (response.data.status === 200) {
        setFeaturedProducts(response.data.data);
      }
    } catch {
      console.log("Issue in featured products.");
    }
  };
  const getMostPopularProducts = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/most_popular_products"
      );
      console.log("Most popular products", response);
      if (response.data.status === 200) {
        setMostpopularproducts(response.data.data);
      }
    } catch {
      console.log("Issue in most popular products.");
    }
  };
  const getMostPopularBrand = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/brands_view"
      );
      // console.log("Most popular brands", response);
      if (response.data.status === 200) {
        setMostpopularbrands(response.data.data);
      }
    } catch {
      console.log("Issue in most popular brands.");
    }
  };
  const getStocks = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/stock_get"
      );
      // console.log("Stocks", response);
      if (response.data.status === 200) {
        setStocks(response.data.data);
      }
    } catch {
      console.log("Issue in most popular brands.");
    }
  };
  const gettwoImg = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/get_two_images"
      );
      // console.log("Twoimages", response);
      if (response.data.status === 200) {
        settwo(response.data.data);
      }
    } catch {
      console.log("Issue in two images.");
    }
  };
  const getsixImg = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/get_gallery"
      );
      console.log("gallery", response.data.data);
      if (response.data.status === 200) {
        setsix(response.data.data);
      }
    } catch {
      console.log("Issue six images");
    }
  };
  const getslider = async () => {
    try {
      let response = await axios.get(baseUrl + "Apicontroller/get_slider");
      console.log("slider data:", response.data);
      if (response.data.status === 200) {
        setData(response.data.data);
      }
    } catch {
      console.log("Issue in getslider.");
    }
  };
  useEffect(() => getslider(), []);
  useEffect(() => {
    getMostPopularBrand();
  }, []);
  useEffect(() => {
    getStocks();
  }, []);
  useEffect(() => {
    getMostPopularProducts();
  }, []);
  useEffect(() => {
    gettwoImg();
  }, []);
  useEffect(() => {
    getsixImg();
  }, []);
  useEffect(() => {
    getFeaturedProducts();
  }, []);
  //================================ START RETURN ================================== 
  return (
    <div className="mt-5 mobile_view_sider">
      {/* //================================ START SLIDER ================================== */}
      <div className="slider-outer">
        <Carousel
          autoPlay={true}
          interval={3000}
          showThumbs={false}
          infiniteLoop={true}
          emulateTouch
          showStatus={false}
        >
          {data.length > 0
            ? data.map((elem, i) => {
              const imageUrl = window.innerWidth >= 650 ? elem.image : elem.app_image;
              return (
                <div>
                  <img src={imageUrl} />
                </div>
              )
            }) : null}
        </Carousel>
      </div>
      {/* //================================ START SLIDER ================================== */}
      {/* //================================ START TWO IMAGES ================================== */}
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <a href="#"><img className="img-fluid" alt="image1" src={TwoImg.image1} /></a>
            </div>
            <div className="col-md-6 pt-sm-mob-5">
              <a href="#"><img className="img-fluid" alt="image2" src={TwoImg.image2} /></a>
            </div>
          </div>
        </div>
      </section>
      {/* //================================ END TWO IMAGES ================================== */}
      {/* //================================ START SIX IMAGES ================================== */}
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row">
            {SixImg
              ? SixImg.map((elem, i) => {
                // console.log(elem.image);
                return (
                  <div className="col-md-4 col-6 mt-3 text-center">
                    <a href={elem.link} target="_blank" rel="noreferrer"><img className="img-fluid" alt={i} src={elem.image} />
                      <h5 style={{ marginTop: '0.7rem' }}>{elem.name}</h5></a>
                  </div>)
              })
              : null}
          </div>
        </div>
      </section>
      {/* //================================ END SIX IMAGES ================================== */}
      {/* //================================ START MOST SELLING PRODUCTS ================================== */}
      <section className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-5">
              <h1 className="under">OUR MOST SELLING PRODUCTS</h1>
            </div>
          </div>
          {mostpopularproducts ? (
            <ProductSlider
              products={mostpopularproducts}
              isSlider={true}
              isBrand={false}
              addingToCartFunction={props.addingToCartFunction}
              mostpopularbrands={[]}
            />
          ) : (
            ""
          )}
        </div>
      </section>
      {/* //================================ END MOST SELLING PRODUCTS ================================== */}
      {/* //================================ START BANNER ================================== */}
      <section className="mt-1 pt-5">
      <div className="container">
          {stocks
            ? stocks.map((item, i) => (
              <>
                <div className={"row " + (i === 1 ? "flex-row-reverse" : "")}>
                  <div className="col-md-6 text-center  p-lg-0 p-md-0">
                    <a href={item.link1}><img src={item.image} alt="" className="img-fluid" /></a>
                  </div>
                  <div className="col-md-6 text-center p-lg-0 p-md-0">
                    <a href={item.link2}><img src={item.image2} alt="" className="img-fluid"/></a>
                  </div>
                </div>
              </>
            ))
            : null}
        </div>
      </section>
      {/* //================================ END BANNER ================================== */}
      {/* //================================ START FEATURED PRODUCTS ================================== */}
      <section className="">
      <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-5">
              <h1 className="under">OUR FEATURED PRODUCTS</h1>
            </div>
          </div>
          {FeaturedProducts ? (
            <ProductSlider
              products={FeaturedProducts}
              isSlider={true}
              isBrand={false}
              addingToCartFunction={props.addingToCartFunction}
              mostpopularbrands={[]}
            />
          ) : (
            ""
          )}
        </div>
      </section>
      {/* //================================ END FEATURED PRODUCTS ================================== */}
      {/* //================================ START MOST POPULAR BRANDS ================================== */}
      <section className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-5">
              <h1 className="under">OUR MOST POPULAR BRANDS</h1>
            </div>
          </div>
          <div className="row  cate_row overflow-hidden">
            <div className="col-md-12">
              <ProductSlider
                isSlider={true}
                products={mostpopularbrands}
                isBrand={true}
              />
            </div>
          </div>
        </div>
      </section>
      {/* //================================ END MOST POPULAR BRANDS ================================== */}
    </div>
  );
}
export default Home;
