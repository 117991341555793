import React from 'react'
function Term() {
    return (
        <div>
            <section className="ptb-50 ptb-xs-30 gray-bg">
                <div className="container">
                    <div className="row testimonial">
                        <div className="col-md-12 sm-6">
                            <p className="MsoNormal" style={{ textAlign: 'center', marginTop: "8em", }} align="center"><b style={{ msoBidiFontWeight: 'normal' }}><span style={{ fontSize: '28.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', }}>Terms &amp; Conditions</span></b></p>
                            <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><span style={{ fontSize: '14.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>Please read
                                this document carefully.</span></p>
                            <div>
                                <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><span style={{ fontSize: '14.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>This is
                                    legal agreement between you and Motoconcept, Jaipur.</span></p>
                                <p className="MsoNormal" style={{ marginBottom: '.0001pt' }}><span style={{ fontSize: '14.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>By joining,
                                    you are agreeing to become bound by the terms of this agreement.&nbsp;</span></p>
                            </div>
                            <p className="MsoNormal"><b style={{ msoBidiFontWeight: 'normal' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>&nbsp;</span></b></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>We reserve the right to make changes to the products and
                                prices listed on www.motoconcept.in, and to other content of this website
                                at any time without prior notice.</span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    While we do our best to ensure that product information on our website is accurate, some inaccuracies, typographical errors or misinterpretations may occur. We reserve the right to correct such inaccuracies or typographical errors as they are identified.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    Image of the product may vary from the actual product.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    Before placing order please check all information about product from manufacturer’s website.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    Warranties are limited on all items. Please check manufacturer’s website to verify the warranty.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    Prices shown on the website www.motoconcept.in are in Indian Rupees and are exclusive of taxes.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    We do not offer any type of price protection in any product. In our product line prices fluctuate frequently.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    All orders are executed after payment realization in favour of MotoConcept, Jaipur.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    All products have their minimum and maximum order quantity limits.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    All product carry manufacturer’s warranty. Within warranty period
                                    defective product must be send to manufacturer or their authorized service provider. Details of service provider are located below the home page. We are not responsible for any warranty.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                            Any product which is dead on arrival (DOA) is not MotoConcept responsibility.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                            All sales are final. Returns are not allowed after 3 days of billing. Returned product must be in good condition and seal packed. Mishandled product shall not be accepted. 4X4 vehicle accessories are non-returnable.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                            If you return On-order category product, you will be charged 30% restocking fee.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    MotoConcept, Jaipur cannot warrant the compatibility of any product for any particular use or purpose. We strongly recommend you to evaluate whether a product will be compatible or not with the given configuration.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    All prices are quoted ex-warehouse Jaipur.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                            Packaging/ Forwarding & Freight on every shipment will be charged, which will be mentioned on the checkout screen after selecting your shipment method or will be conveyed to you telephonically by accounts department.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    We encourage all partners to thoroughly review their order, shipping & billing information.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                            We do not ensure to dispatch your order in the same day if your order is placed after 6 pm.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                            You will receive a confirmation message/call once your order is shipped.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    We are using standard shipping companies with tracking number.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    Freight is charged on every shipment on the basis of distance & number of parcels.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                                    Shipment through transport will be send on To Pay basis.
                                </span></p>
                            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in', msoList: 'l0 level1 lfo1' }}><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', fontFamily: 'Symbol', msoFareastFontFamily: 'Symbol', msoBidiFontFamily: 'Symbol' }}><span style={{ msoList: 'Ignore' }}>·<span style={{ font: '7.0pt' }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></span><span style={{ fontSize: '12.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%' }}>
                            If there is a problem in delivery, please contact the courier/ transport company directly prior to contacting us.
                                </span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Term
