import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import ProductCard from "./ProductCard";
function Subcategory(props) {
  const [allproduct, setallProduct] = useState([]);
  const [subcategoryName, setSubCategoryName] = useState([]);
  const [modalDis, setModalDis] = useState(false);
  const [brands, setBrands] = useState([]);
  const subcategory_id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  async function fetchAllProductData() {
    const formData = new FormData();
    formData.append("subcategory_id", subcategory_id);
    try {
      const data = await axios.post(
        baseUrl + "Apicontroller/get_subcategory_products",
        formData
      );
      console.log("subcategoryName products all pd:", data);
      setallProduct(data.data.data);
      setSubCategoryName(data.data.subcategory_name);
    } catch {
      console.log("Issue in get product detail.");
    }
  }
  useEffect(() => {
    fetchAllProductData();
    getBrands();
  }, [subcategory_id]);
  //=========================== fetch filter data======================
  const getBrands = async () => {
    try {
      let response = await axios.get(baseUrl + "Apicontroller/get_brands");
      console.log("brandsresponse:", response.data);
      if (response.data.status === 200) {
        setBrands(response.data.data);
      }
    } catch {
      console.log("Issue in get category.");
    }
    console.log("brands:", brands);
  };
  const [FilterName, setFN] = useState({
    type: [],
    wattage: [],
    size: [],
    filter_product: [],
    color: [],
    brand_data: [],
  });
  const [emptyMessage, setEmptyMessage] = useState("");
  const [type, settype] = useState("");
  const [wattage, setwattage] = useState("");
  const [size, setsize] = useState("");
  const [filter_product, setfilter_product] = useState("");
  const [color, setcolor] = useState("");
  const [model, setModel] = useState("");
  const [filter, setfilter] = useState('');
  const [brand, setbrand] = useState('');
  useEffect(() => {
    async function fetchFilterNameData() {
      try {
        const response = await axios(baseUrl + "Apicontroller/view_filter/" + subcategory_id);
        console.log("Filter name:", response.data.data, response.data.status);
        if (response.data.status === 200) {
          setFN(response.data.data[0]);
          if (response.data.data[0].type.length > 0) {
            setfilter(1)
          } else if (response.data.data[0].wattage.length > 0) {
            setfilter(1)
          } else if (response.data.data[0].size.length > 0) {
            setfilter(1)
          } else if (response.data.data[0].filter_product.length > 0) {
            setfilter(1)
          } else if (response.data.data[0].color.length > 0) {
            setfilter(1)
          } else if (response.data.data[0].brand_data.length > 0) {
            setfilter(1)
          } else {
            setfilter('')
          }
        }
        // setAP(response.data.data);
      } catch {
        console.log("Issue in filter name");
      }
    }
    fetchFilterNameData();
  }, [subcategory_id]);
  useEffect(() => {
    applyingFilterAPI()
  }, [type, wattage, size, filter_product, color, model, brand]);
  const setFilters = (e) => {
    console.log("e k andar", e.target.attributes.filterid, e.target.id);
    const filterId = document
      .getElementById(e.target.id)
      .getAttribute("filterId");
    const filterName = document
      .getElementById(e.target.id)
      .getAttribute("filterName");
    if (filterName === "brand") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (brand === "") {
          setbrand(filterId);
        } else {
          let newdata = brand + "," + filterId;
          setbrand(newdata);
        }
      } else {
        if (brand !== "") {
          let prevArray = brand.split(",");
          let index = brand.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            brand.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setbrand(newdata);
        }
      }
    }
    if (filterName === "type") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (type === "") {
          settype(filterId);
        } else {
          let newdata = type + "," + filterId;
          settype(newdata);
        }
      } else {
        if (type !== "") {
          let prevArray = type.split(",");
          let index = type.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            type.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          settype(newdata);
        }
      }
    }
    if (filterName === "wattage") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (wattage === "") {
          setwattage(filterId);
        } else {
          let newdata = wattage + "," + filterId;
          setwattage(newdata);
        }
      } else {
        if (wattage !== "") {
          let prevArray = wattage.split(",");
          let index = wattage.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            wattage.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setwattage(newdata);
        }
      }
    }
    if (filterName === "size") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (size === "") {
          setsize(filterId);
        } else {
          let newdata = size + "," + filterId;
          setsize(newdata);
        }
      } else {
        if (size !== "") {
          let prevArray = size.split(",");
          let index = size.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            size.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setsize(newdata);
        }
      }
    }
    if (filterName === "filter_product") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (filter_product === "") {
          setfilter_product(filterId);
        } else {
          let newdata = filter_product + "," + filterId;
          setfilter_product(newdata);
        }
      } else {
        if (filter_product !== "") {
          let prevArray = filter_product.split(",");
          let index = filter_product.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            filter_product.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setfilter_product(newdata);
        }
      }
    }
    if (filterName === "color") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (color === "") {
          setcolor(filterId);
        } else {
          let newdata = color + "," + filterId;
          setcolor(newdata);
        }
      } else {
        if (color !== "") {
          let prevArray = color.split(",");
          let index = color.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            color.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setcolor(newdata);
        }
      }
    }
    if (filterName === "model") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (model === "") {
          setModel(filterId);
        } else {
          let newdata = model + "," + filterId;
          setModel(newdata);
        }
      } else {
        if (model !== "") {
          let prevArray = model.split(",");
          let index = model.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            model.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setModel(newdata);
        }
      }
    }
  };
  //================================show filter ===================
  const openFilter = () => {
    document.querySelector(".side_filter").style.transform = "translateX(0px)";
  }
  //================================close filter ===================
  const closeFilter = () => {
    document.querySelector(".side_filter").style.transform = "translateX(900px)";
  }
  const applyingFilterAPI = async () => {
    console.log("this is my type", model);
    if (
      type !== "" ||
      wattage !== "" ||
      size !== "" ||
      filter_product !== "" ||
      color !== "" ||
      model !== "" ||
      brand !== ""
    ) {
      // alert("hi")
      console.log("Applying filter api", model);
      const appliedFilterData = new FormData();
      appliedFilterData.append("brand", brand);
      appliedFilterData.append("type_id", type);
      appliedFilterData.append("wattage_id", wattage);
      appliedFilterData.append("size_id", size);
      appliedFilterData.append("filter_product_id", filter_product);
      appliedFilterData.append("color_id", color);
      appliedFilterData.append("model", model);
      appliedFilterData.append("subcategory_id", subcategory_id);
      for (let pair of appliedFilterData.entries()) {
        console.log(pair[0], ":", pair[1]);
      }
      try {
        const response = await axios.post(baseUrl + "Apicontroller/filter", appliedFilterData);
        console.log("filtered product data", response);
        if (response.data.status === 201) {
          setallProduct([]);
          setEmptyMessage("No product available.");
        } else {
          console.log("filtered product data", response.data.data);
          setallProduct(response.data.data);
          setEmptyMessage("");
        }
      } catch {
        console.log("Issue in get filterd data");
      }
    } else {
      fetchAllProductData();
    }
  };
  return (
    <div>
      {/* filter */}
      <section className="filter pt-5 mt-5 accordion" id="accordionExample">
        <div className="container-fluid" style={{ maxWidth: "100vw" }}>
          <div className="row heading-div">
            <div className="col-md-12 text-center">
              <h1 className="heading">{subcategoryName}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="filter_img mt-3">
        <div className="container-fluid px-4">
          {/* ===========================  mob view toogle filter ======================== */}
          <div className="d-block d-md-none mb-3">
            <div className="row d-flex text-right justify-content-end">
              <div className="col-5 py-2 d-flex text-right border justify-content-end" onClick={() => { openFilter() }}>
                <div><p className="mb-0">Filters</p></div>
                <div><i className="fa fa-filter px-2" style={{ fontSize: "1.4rem" }}></i></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={"col-md-3 col-12 desktopfilter " + (filter === 1 ? 'd-block' : 'd-none')}>
              {filter ?
                <div className="border p-2 mb-2">
                  <div className={'text-center mb-2'}>
                    <h5>Filters</h5>
                    {/* <button className={'c_btn2'}
                      onClick={() => {
                        return applyingFilterAPI()
                      }}>Apply Filter</button> */}
                  </div>
                  <div className="accordion filter_shopbycar" id="filter_shopbycar">
                    {/* ========================== brand =========================         */}
                    {FilterName.brand_data.length > 0 ?
                      <div className="card">
                        <div className="card-header shopBycar" id='brand'>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse_brand"}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              Brand
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse_brand"}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <ul style={{ fontSize: "20px", }}
                                className="pl-0 mt-0 mb-0 mt-2">
                                {FilterName ? FilterName.brand_data.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <li style={{ fontSize: "16px", transform: 'none' ,display:'flex',alignItems:'baseline' }}  >
                                      <input type="checkbox" name="brand" className="CheckShopbycar"
                                        id={"brand" + elem.id}
                                        filterid={elem.id}
                                        filtername="brand"
                                        onClick={(e) => setFilters(e)} />
                                      <label for={"brand" + elem.id} className="mx-2 m-0">
                                        {elem.name}</label>
                                    </li>
                                  );
                                })
                                  : ""}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                      : null}
                    {/* ========================== type =========================         */}
                    {FilterName.type.length > 0 ?
                      <div className="card">
                        <div className="card-header shopBycar" id='type'>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse_type"}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              Type
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse_type"}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <ul style={{ fontSize: "20px", }}
                                className="pl-0 mt-0 mb-0 mt-2">
                                {FilterName ? FilterName.type.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                      <input type="checkbox" name="type" className="CheckShopbycar"
                                        id={"type" + elem.id}
                                        filterid={elem.id}
                                        filtername="type"
                                        onClick={(e) => setFilters(e)} />
                                      <label for={"type" + elem.id} className="mx-2 m-0">
                                        {elem.name}</label>
                                    </li>
                                  );
                                })
                                  : ""}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                      : null}
                    {/* ========================== wattage =========================         */}
                    {FilterName.wattage.length > 0 ?
                      <div className="card">
                        <div className="card-header shopBycar" id='wattage'>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse_wattage"}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              Wattage
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse_wattage"}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <ul style={{ fontSize: "20px", }}
                                className="pl-0 mt-0 mb-0 mt-2">
                                {FilterName ? FilterName.wattage.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                      <input type="checkbox" name="wattage" className="CheckShopbycar"
                                        id={"wattage" + elem.id}
                                        filterid={elem.id}
                                        filtername="wattage"
                                        onClick={(e) => setFilters(e)} />
                                      <label for={"wattage" + elem.id} className="mx-2 m-0">
                                        {elem.name}</label>
                                    </li>
                                  );
                                })
                                  : ""}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                      : null}
                    {/* ========================== size =========================         */}
                    {FilterName.size.length > 0 ?
                      <div className="card">
                        <div className="card-header shopBycar" id='size'>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse_size"}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              Size
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse_size"}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <ul style={{ fontSize: "20px", }}
                                className="pl-0 mt-0 mb-0 mt-2">
                                {FilterName ? FilterName.size.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                      <input type="checkbox" name="size" className="CheckShopbycar"
                                        id={"size" + elem.id}
                                        filterid={elem.id}
                                        filtername="size"
                                        onClick={(e) => setFilters(e)} />
                                      <label for={"size" + elem.id} className="mx-2 m-0">
                                        {elem.name}</label>
                                    </li>
                                  );
                                })
                                  : ""}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                      : null}
                    {/* ========================== product =========================         */}
                    {FilterName.filter_product.length > 0 ?
                      <div className="card">
                        <div className="card-header shopBycar" id='product'>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse_product"}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              Product
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse_product"}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <ul style={{ fontSize: "20px", }}
                                className="pl-0 mt-0 mb-0 mt-2">
                                {FilterName ? FilterName.filter_product.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                      <input type="checkbox" name="filter_product" className="CheckShopbycar"
                                        id={"filter_product" + elem.id}
                                        filterid={elem.id}
                                        filtername="filter_product"
                                        onClick={(e) => setFilters(e)} />
                                      <label for={"filter_product" + elem.id} className="mx-2 m-0">
                                        {elem.name}</label>
                                    </li>
                                  );
                                })
                                  : ""}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                      : null}
                    {/* ========================== color =========================         */}
                    {FilterName.color.length > 0 ?
                      <div className="card">
                        <div className="card-header shopBycar" id='color'>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse_color"}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              Color
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse_color"}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <ul style={{ fontSize: "20px", }}
                                className="pl-0 mt-0 mb-0 mt-2">
                                {FilterName ? FilterName.color.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <li style={{ fontSize: "16px", transform: 'none' ,display:'flex',alignItems:'baseline' }}  >
                                      <input type="checkbox" name="color" className="CheckShopbycar"
                                        id={"color" + elem.id}
                                        filterid={elem.id}
                                        filtername="color"
                                        onClick={(e) => setFilters(e)} />
                                      <label for={"color" + elem.id} className="mx-2 m-0">
                                        {elem.name}</label>
                                    </li>
                                  );
                                })
                                  : ""}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                      : null}
                    
                    {subcategoryName === 'Infotainment System' || subcategoryName === 'Seat Cover' || subcategoryName === 'Floor Matts' || subcategoryName === 'Window Sunshades' || subcategoryName === 'Body Cover' || subcategoryName === 'Door Visor' || subcategoryName === 'Headlights' || subcategoryName === 'Tail Lights' ?
                      <div className="card">
                        <div className="card-header shopBycar" id='Brands'>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse0"}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              Car Brands
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse0"}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <div className="accordion filter_shopbycar" id="filter_shopbycar2">
                                {brands ? brands.map((x) => {
                                  if (subcategoryName === 'Infotainment System' || subcategoryName === 'Seat Cover' || subcategoryName === 'Floor Matts' || subcategoryName === 'Window Sunshades' || subcategoryName === 'Body Cover' || subcategoryName === 'Door Visor' || subcategoryName === 'Headlights' || subcategoryName === 'Tail Lights') {
                                    return (
                                      <div className="card">
                                        <div className="card-header shopBycar" id={x.name}>
                                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                                            data-toggle="collapse"
                                            data-target={"#collapse_car" + x.id}
                                            aria-expanded="true"
                                            aria-controls={"#collapse2"}
                                          >
                                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                                              {x.name}
                                            </span>
                                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                                              <i className="fa fa-chevron-circle-down"></i>
                                            </button>
                                          </h2>
                                        </div>
                                        <div
                                          id={"collapse_car" + x.id}
                                          className="collapse"
                                          aria-labelledby={"heading2"}
                                          data-parent="#filter_shopbycar2">
                                          <>
                                            <div className="card-body shopBycar_item2">
                                              <ul style={{ fontSize: "20px", }}
                                                className="pl-0 mt-0 mb-0 mt-2">
                                                {x ? x.car_model.map((elem) => {
                                                  return (
                                                    <li style={{ fontSize: "16px", transform: 'none' ,display:'flex',alignItems:'baseline' }}  >
                                                      <input type="checkbox" name="name" id={elem.id} className="CheckShopbycar2"
                                                        filterid={elem.id}
                                                        filtername="model"
                                                        onClick={(e) => setFilters(e)} />
                                                      <label for={elem.id} className="mx-2 m-0">
                                                        {elem.name}</label>
                                                    </li>
                                                  );
                                                })
                                                  : ""}
                                              </ul>
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                  : ""}
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      : null}
                  </div>
                </div>
                : null}
            </div>
            <div className="col-md-9 col-12 p-0  px-2">
              <div className="row">
                {allproduct.length > 0
                  ? allproduct.map((elem) => {
                    return (
                      <ProductCard
                        product_id={elem.product_id}
                        productimage={elem.image ? elem.image : elem.product_image}
                        productname={elem.product_name}
                        mrp={elem.mrp ? elem.mrp : elem.MRP}
                        price={elem.price}
                        stock={elem.stock}
                        addingToCartFunction={props.addingToCart}
                        isSlider="false"
                      />
                    );
                  })
                  :
                  <div className="text-center w-100 mt-5">
                    <h5>No Result Found</h5>
                  </div>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 text-center">{emptyMessage}</div>
          </div>
        </div>
      </section>
      {/* ========================  mob view right offcanvas =================== */}
      <section className="side_filter d-block d-md-none">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span className="close_side d-flex text-white" style={{ fontSize: "1.5rem" }} onClick={() => { closeFilter() }}>X</span>
            </div>
            <div className="col-12 p-0 mt-5 pt-2">
              <div className={' mb-2'}>
                <div className="text-center"><p className="mb-0" style={{ color: "white", fontSize: "20px" }}>Filters</p></div>
                {/* <button className={'text-danger btn btn-light mx-2 font-weight-bold'}
                  onClick={() => {
                    return applyingFilterAPI(), closeFilter()
                  }}>Apply Filter</button> */}
              </div>
              <div className="accordion filter_shopbycar" id="filter_shopbycar">
                {/* ========================== brand =========================         */}
                {FilterName.brand_data.length > 0 ?
                  <div className="card">
                    <div className="card-header shopBycar" id='brand'>
                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                        data-toggle="collapse"
                        data-target={"#collapse_brand"}
                        aria-expanded="true"
                        aria-controls={"#collapse"}
                      >
                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                          Brand
                        </span>
                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                          <i className="fa fa-chevron-circle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={"collapse_brand"}
                      className="collapse"
                      aria-labelledby={"heading"}
                      data-parent="#filter_shopbycar">
                      <>
                        <div className="card-body shopBycar_item">
                          <ul style={{ fontSize: "20px", }}
                            className="pl-0 mt-0 mb-0 mt-2">
                            {FilterName ? FilterName.brand_data.map((elem, i) => {
                              let filterId = i + 1;
                              return (
                                <li style={{ fontSize: "16px", transform: 'none' ,display:'flex',alignItems:'baseline' }}  >
                                  <input type="checkbox" name="brand" className="CheckShopbycar"
                                    id={"brand" + elem.id}
                                    filterid={elem.id}
                                    filtername="brand"
                                    onClick={(e) => setFilters(e)} />
                                  <label for={"brand" + elem.id} className="mx-2 m-0">
                                    {elem.name}</label>
                                </li>
                              );
                            })
                              : ""}
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                  : null}
                {/* ========================== type =========================         */}
                {FilterName.type.length > 0 ?
                  <div className="card">
                    <div className="card-header shopBycar" id='type'>
                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                        data-toggle="collapse"
                        data-target={"#collapse_type"}
                        aria-expanded="true"
                        aria-controls={"#collapse"}
                      >
                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                          Type
                        </span>
                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                          <i className="fa fa-chevron-circle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={"collapse_type"}
                      className="collapse"
                      aria-labelledby={"heading"}
                      data-parent="#filter_shopbycar">
                      <>
                        <div className="card-body shopBycar_item">
                          <ul style={{ fontSize: "20px", }}
                            className="pl-0 mt-0 mb-0 mt-2">
                            {FilterName ? FilterName.type.map((elem, i) => {
                              let filterId = i + 1;
                              return (
                                <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline' }}  >
                                  <input type="checkbox" name="type" className="CheckShopbycar"
                                    id={"type" + elem.id}
                                    filterid={elem.id}
                                    filtername="type"
                                    onClick={(e) => setFilters(e)} />
                                  <label for={"type" + elem.id} className="mx-2 m-0">
                                    {elem.name}</label>
                                </li>
                              );
                            })
                              : ""}
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                  : null}
                {/* ========================== wattage =========================         */}
                {FilterName.wattage.length > 0 ?
                  <div className="card">
                    <div className="card-header shopBycar" id='wattage'>
                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                        data-toggle="collapse"
                        data-target={"#collapse_wattage"}
                        aria-expanded="true"
                        aria-controls={"#collapse"}
                      >
                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                          Wattage
                        </span>
                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                          <i className="fa fa-chevron-circle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={"collapse_wattage"}
                      className="collapse"
                      aria-labelledby={"heading"}
                      data-parent="#filter_shopbycar">
                      <>
                        <div className="card-body shopBycar_item">
                          <ul style={{ fontSize: "20px", }}
                            className="pl-0 mt-0 mb-0 mt-2">
                            {FilterName ? FilterName.wattage.map((elem, i) => {
                              let filterId = i + 1;
                              return (
                                <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                  <input type="checkbox" name="wattage" className="CheckShopbycar"
                                    id={"wattage" + elem.id}
                                    filterid={elem.id}
                                    filtername="wattage"
                                    onClick={(e) => setFilters(e)} />
                                  <label for={"wattage" + elem.id} className="mx-2 m-0">
                                    {elem.name}</label>
                                </li>
                              );
                            })
                              : ""}
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                  : null}
                {/* ========================== size =========================         */}
                {FilterName.size.length > 0 ?
                  <div className="card">
                    <div className="card-header shopBycar" id='size'>
                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                        data-toggle="collapse"
                        data-target={"#collapse_size"}
                        aria-expanded="true"
                        aria-controls={"#collapse"}
                      >
                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                          Size
                        </span>
                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                          <i className="fa fa-chevron-circle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={"collapse_size"}
                      className="collapse"
                      aria-labelledby={"heading"}
                      data-parent="#filter_shopbycar">
                      <>
                        <div className="card-body shopBycar_item">
                          <ul style={{ fontSize: "20px", }}
                            className="pl-0 mt-0 mb-0 mt-2">
                            {FilterName ? FilterName.size.map((elem, i) => {
                              let filterId = i + 1;
                              return (
                                <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                  <input type="checkbox" name="size" className="CheckShopbycar"
                                    id={"size" + elem.id}
                                    filterid={elem.id}
                                    filtername="size"
                                    onClick={(e) => setFilters(e)} />
                                  <label for={"size" + elem.id} className="mx-2 m-0">
                                    {elem.name}</label>
                                </li>
                              );
                            })
                              : ""}
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                  : null}
                {/* ========================== product =========================         */}
                {FilterName.filter_product.length > 0 ?
                  <div className="card">
                    <div className="card-header shopBycar" id='product'>
                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                        data-toggle="collapse"
                        data-target={"#collapse_product"}
                        aria-expanded="true"
                        aria-controls={"#collapse"}
                      >
                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                          Product
                        </span>
                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                          <i className="fa fa-chevron-circle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={"collapse_product"}
                      className="collapse"
                      aria-labelledby={"heading"}
                      data-parent="#filter_shopbycar">
                      <>
                        <div className="card-body shopBycar_item">
                          <ul style={{ fontSize: "20px", }}
                            className="pl-0 mt-0 mb-0 mt-2">
                            {FilterName ? FilterName.filter_product.map((elem, i) => {
                              let filterId = i + 1;
                              return (
                                <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                  <input type="checkbox" name="filter_product" className="CheckShopbycar"
                                    id={"filter_product" + elem.id}
                                    filterid={elem.id}
                                    filtername="filter_product"
                                    onClick={(e) => setFilters(e)} />
                                  <label for={"filter_product" + elem.id} className="mx-2 m-0">
                                    {elem.name}</label>
                                </li>
                              );
                            })
                              : ""}
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                  : null}
                {/* ========================== color =========================         */}
                {FilterName.color.length > 0 ?
                  <div className="card">
                    <div className="card-header shopBycar" id='color'>
                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                        data-toggle="collapse"
                        data-target={"#collapse_color"}
                        aria-expanded="true"
                        aria-controls={"#collapse"}
                      >
                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                          Color
                        </span>
                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                          <i className="fa fa-chevron-circle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={"collapse_color"}
                      className="collapse"
                      aria-labelledby={"heading"}
                      data-parent="#filter_shopbycar">
                      <>
                        <div className="card-body shopBycar_item">
                          <ul style={{ fontSize: "20px", }}
                            className="pl-0 mt-0 mb-0 mt-2">
                            {FilterName ? FilterName.color.map((elem, i) => {
                              let filterId = i + 1;
                              return (
                                <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                  <input type="checkbox" name="color" className="CheckShopbycar"
                                    id={"color" + elem.id}
                                    filterid={elem.id}
                                    filtername="color"
                                    onClick={(e) => setFilters(e)} />
                                  <label for={"color" + elem.id} className="mx-2 m-0">
                                    {elem.name}</label>
                                </li>
                              );
                            })
                              : ""}
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                  : null}
                
                {subcategoryName === 'Infotainment System' || subcategoryName === 'Seat Cover' || subcategoryName === 'Floor Matts' || subcategoryName === 'Window Sunshades' || subcategoryName === 'Body Cover' || subcategoryName === 'Door Visor' || subcategoryName === 'Headlights' || subcategoryName === 'Tail Lights' ?
                  <div className="card">
                    <div className="card-header shopBycar" id='Brandsss'>
                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                        data-toggle="collapse"
                        data-target={"#collapse_0"}
                        aria-expanded="true"
                        aria-controls={"#collapse"}
                      >
                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                          Car Brands
                        </span>
                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                          <i className="fa fa-chevron-circle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={"collapse_0"}
                      className="collapse"
                      aria-labelledby={"heading"}
                      data-parent="#filter_shopbycar">
                      <>
                        <div className="card-body shopBycar_item">
                          <div className="accordion filter_shopbycar" id="filter_shopbycar">
                            {brands ? brands.map((x) => {
                              if (subcategoryName === 'Infotainment System' || subcategoryName === 'Seat Cover' || subcategoryName === 'Floor Matts' || subcategoryName === 'Window Sunshades' || subcategoryName === 'Body Cover' || subcategoryName === 'Door Visor' || subcategoryName === 'Headlights' || subcategoryName === 'Tail Lights') {
                                return (
                                  <div className="card">
                                    <div className="card-header shopBycar" id={x.name}>
                                      <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                                        data-toggle="collapse"
                                        data-target={"#collapse_carss" + x.id}
                                        aria-expanded="true"
                                        aria-controls={"#collapse2"}
                                      >
                                        <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                                          {x.name}
                                        </span>
                                        <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                                          <i className="fa fa-chevron-circle-down"></i>
                                        </button>
                                      </h2>
                                    </div>
                                    <div
                                      id={"collapse_carss" + x.id}
                                      className="collapse"
                                      aria-labelledby={"heading2"}
                                      data-parent="#filter_shopbycar">
                                      <>
                                        <div className="card-body shopBycar_item2">
                                          <ul style={{ fontSize: "20px", }}
                                            className="pl-0 mt-0 mb-0 mt-2">
                                            {x ? x.car_model.map((elem) => {
                                              return (
                                                <li style={{ fontSize: "16px", transform: 'none',display:'flex',alignItems:'baseline'  }}  >
                                                  <input type="checkbox" name="name" id={elem.id} className="CheckShopbycar2"
                                                    filterid={elem.id}
                                                    filtername="model"
                                                    onClick={(e) => setFilters(e)} />
                                                  <label for={elem.id} className="mx-2 m-0">
                                                    {elem.name}</label>
                                                </li>
                                              );
                                            })
                                              : ""}
                                          </ul>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                );
                              }
                            })
                              : ""}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Subcategory;