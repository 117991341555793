import React, { useState } from "react";
import { State } from "./State";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import { Redirect } from "react-router";

function Login(props) {
  const loginUser = async (e) => {
    e.preventDefault();
    const loginData = new FormData(e.target);
    try {
      const response = await axios.post(
        baseUrl + "Users/login",
        loginData
      );
      console.log("login", response.data);
      if (response.data.code === 201) {
        document.querySelector("#loginError").innerHTML = response.data.message;
      } else if (response.data.code === 200) {
        document.querySelector("#loginError").innerHTML = "Check for otp!";
        const loginphone = document.querySelector("#loginphone").value;
        localStorage.setItem("supremetech_phone", loginphone);
        props.history.push("/login-otp");
      }
    } catch {
      console.log("Issue in login");
    }
  };
  const [contact, setContact] = useState("")
  const validateSignupForm = (e) => {
    const reg = /^\d+$/;
    if (reg.test(e.target.value) || e.target.value === "") {
      setContact(e.target.value);
    }
  };
  if (
    localStorage.getItem("supremetech_authentication") === "" ||
    localStorage.getItem("supremetech_authentication") === null
  ) {
    return (
      <div>
        <section className="mt-5 mb-5 pt-5 pb-5 login_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 heading-div text-center mb-5">
                <h2 className="title">DEALER LOGIN</h2>
              </div>
              {/* <div className="row"> */}
              <div className="col-md-3"></div>
              <div className="col-md-6">
                {/* <h6>Login</h6>
                <hr /> */}
                <p id="loginError" className="font_monst gry_col mb-4">
                
                </p>
                <form
                  id="loginform"
                  className="form"
                  onSubmit={(e) => loginUser(e)}
                >
                  <input
                    type="text"
                    name="phone"
                    id="loginphone"
                    placeholder="Enter Your Registered Mobile Number"
                    className="mb-4"
                    minLength={10}
                    value={contact}
                    maxLength="10"
                    onChange={(e) => validateSignupForm(e)}
                  />
                  <div className="d-flex mt-3 justify-content-between">
                    <button
                      style={{ background: "#dc3545", color: "white" }}
                      type="submit"
                      className="btn knw_btn active c_btn2"
                    >
                      Send OTP
                    </button>
                  </div>
                </form>
              </div>
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}

export default Login;
