import React, { useEffect } from "react";
import { Redirect } from "react-router";
export default function Profile(props) {
  useEffect(() => {
    const authentication = localStorage.getItem("supremetech_authentication");
    const phone = localStorage.getItem("supremetech_phone");
    const email = localStorage.getItem("email");
    const company_name = localStorage.getItem("company_name");
    if (authentication === "" && phone === "") {
      props.history.push("/");
    }
  }, [
    localStorage.getItem("supremetech_authentication"),
    localStorage.getItem("supremetech_phone"),
  ]);
  if (
    !(localStorage.getItem("supremetech_authentication") === "" || localStorage.getItem("supremetech_authentication") === null)
  ) {
    return (
      <div className="container pt-5 pt-lg-5 mt-md-5 mt-4">
        <div className="row heading-div">
          <div className="col-md-12 text-center">
            <h1 className="heading">My Account</h1>
          </div>
        </div>
        <div className="main-body">
          <div className="row gutters-sm">
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Full Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {localStorage.getItem("supremetech_name")}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {localStorage.getItem("supremetech_phone")}
                    </div>
                  </div>
                  {localStorage.getItem("email") &&
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Email</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {localStorage.getItem("email")}
                      </div>
                    </div>
                  }
                  {localStorage.getItem("company_name") &&
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Company Name</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {localStorage.getItem("company_name")}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/register" />;
  }
}
