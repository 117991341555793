import { CompareArrowsOutlined } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { Redirect } from "react-router";
import { baseUrl } from "./baseUrl";
import { State } from "./State";
function Checkout(props) {
  const [subTotal, setSubTotal] = useState(0);
  const [total, setOrderTotal] = useState(0);
  const [prevAddress, setPrevAddress] = useState([]);
  const [calculatedData, setCalD] = useState({});
  const [showPopUp, setSPU] = useState(true);
  const [popUpMessage, setPUM] = useState("");
  const [storeDetails, setStoreDetails] = useState([]);
  const [btImg, setBTImage] = useState([]);
  const [States, setStates] = useState([]);
  const [PaymentMethod, setPaymentMethod] = useState(1);
  var [promoCode, setPC] = useState("");
  const [txn_id, setTxn_id] = useState("");
  const [store_id, setStore_id] = useState(1)
  const [Promo, setPromo] = useState("")
  const [promocodeDiscount, setPromoDis] = useState(0);
  const [PromoId, setPromoId] = useState('');
  const [PromoName, setPromoName] = useState('');
  //---------------address ------------
  const [Name, setName] = useState("")
  const [Address, setAddress] = useState("")
  const [City, setCity] = useState("")
  const [Statee, setState] = useState("")
  const [Phone, setPhone] = useState("")
  const [PaymentType, setPaymentType] = useState(1)
  useEffect(() => {
    getState();
  }, []);
  const getState = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/all_state_get"
      );
      console.log("all_state_get", response);
      if (response.data.status === 200) {
        setStates(response.data.data);
      }
    } catch {
      console.log("Issue in all_state_get.");
    }
  };
  //---------------------------------promocode apply ----------
  const ApplyPromo = () => {
    if (Promo.trim() === '') {
      // alert(Promo)
      return;
    } else {
      CheckPromo();
    }
  }
  //--------------------- apply promocode ---------------------
  const CheckPromo = async () => {
    const dataForCalculate = new FormData();
    dataForCalculate.append("phone", localStorage.getItem("supremetech_phone"));
    dataForCalculate.append("token_id", token);
    dataForCalculate.append("authentication", localStorage.getItem("supremetech_authentication"));
    dataForCalculate.append("txn_id", txn_id);
    dataForCalculate.append("promocode", Promo);
    try {
      let response = await axios.post(
        baseUrl + "Apicontroller/apply_promocode",
        dataForCalculate
      );
      // console.log("promocode data:", response.data);
      if (response.data.status == '200') {
        setSubTotal(response.data.data.sub_total);
        setPromoDis(response.data.data.promocode_discount);
        setOrderTotal(response.data.data.total);
        setPromoId(response.data.data.promocode_id);
        setPromoName(Promo);
        props.changePopupText("Success! Promocode apply successfully");
      } else {
        props.changePopupText(response.data.message);
      }
    } catch {
      console.log("Issue in promocode!");
    }
  }
  //--------------------------remove promoocode -----------------------------
  const RemovePromo = async () => {
    const dataForCalculate = new FormData();
    dataForCalculate.append("phone", localStorage.getItem("supremetech_phone"));
    dataForCalculate.append("token_id", token);
    dataForCalculate.append("authentication", localStorage.getItem("supremetech_authentication"));
    dataForCalculate.append("txn_id", txn_id);
    dataForCalculate.append("promocode_id", PromoId);
    try {
      let response = await axios.post(
        baseUrl + "Apicontroller/promocode_remove",
        dataForCalculate
      );
      console.log("promocode data:", response.data.data);
      if (response.status === 200) {
        setSubTotal(response.data.data.sub_total);
        setPromoDis('');
        setOrderTotal(response.data.data.sub_total);
        setPromoId('');
        setPromoName('');
        props.changePopupText("Success! Promocode Removed successfully");
      } else {
        props.changePopupText(response.message);
      }
    } catch {
      console.log("Issue in promocode!");
    }
  }
  // const [promoErrMessage, setPromoErrMessage] = useState("Apply promo code");
  const toggleShowPopUp = () => setSPU(!showPopUp);
  const radioHandler = (e) => {
    if (e === 1) {
      setPaymentType(1)
      document.getElementById("banktranfer").style.display = "none";
    } else {
      setPaymentType(2)
      document.getElementById("banktranfer").style.display = "block";
    }
  };
  const token = localStorage.getItem("user_token");
  const getCalculations = async () => {
    console.log(localStorage.getItem("supremetech_phone"), token, localStorage.getItem("supremetech_authentication"))
    const dataForCalculate = new FormData();
    dataForCalculate.append("phone", localStorage.getItem("supremetech_phone"));
    dataForCalculate.append("token_id", token);
    dataForCalculate.append(
      "authentication",
      localStorage.getItem("supremetech_authentication")
    );
    try {
      let response = await axios.post(
        baseUrl + "Apicontroller/calculate",
        dataForCalculate
      );
      console.log("calculate data:", response);
      if (response.data.status === 200) {
        setCalD(response.data);
        setSubTotal(response.data.subtotal);
        setOrderTotal(response.data.total);
        setPrevAddress(response.data.address);
        setTxn_id(response.data.txn_id);
        const newData = JSON.stringify(response.data);
        localStorage.setItem("supreme_tech_calculations", newData);
      } else {
        props.history.push("/");
      }
    } catch {
      console.log("Issue in calculate.");
    }
  };
  // setMyLS(myLSData)
  // ************ getting Local Storage Data *************************
  const getStoreDetails = async () => {
    const dataForStoreDetails = new FormData();
    dataForStoreDetails.append(
      "phone",
      localStorage.getItem("supremetech_phone")
    );
    dataForStoreDetails.append("token_id", token);
    dataForStoreDetails.append(
      "authentication",
      localStorage.getItem("supremetech_authentication")
    );
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/store_details",
        dataForStoreDetails
      );
      console.log("Store details:", response);
      if (response.data.status === 200) {
        setStoreDetails(response.data.data);
        console.log("this is store details: ", response.data.data)
        document.querySelector("#store_1").style.backgroundColor = "black";
        document.querySelector("#store_1").style.color = "white";
        getViewData();
      }
    } catch {
      console.log("Issue in Store details");
    }
  };
  useEffect(() => {
    getViewData();
  }, []);
  useEffect(() => {
    getCalculations();
  }, [localStorage.getItem("user_token")]);
  const payingThroughStore = (e, elem) => {
    if (elem.id == 1) {
      document.querySelector("#store_1").style.backgroundColor = "black";
      document.querySelector("#store_1").style.color = "white";
      document.querySelector("#store_2").style.backgroundColor = "white";
      document.querySelector("#store_2").style.color = "black";
    } else if (elem.id == 2) {
      document.querySelector("#store_1").style.backgroundColor = "white";
      document.querySelector("#store_1").style.color = "black";
      document.querySelector("#store_2").style.backgroundColor = "black";
      document.querySelector("#store_2").style.color = "white";
    }
    console.log("we selected this elem: ", elem)
    setStore_id(elem.id)
  }
  // ========================================================================================
  const ShowPayAtPlace = (e) => {
    setPaymentMethod(2)
    document.querySelector("#payAtStore").style.display = "none";
    document.querySelector("#PayAtPlaceBtn").style.display = "block";
    document.querySelector("#payAtStoreBtn").style.backgroundColor = "black";
    document.querySelector("#ShowPayAtPlaceBtn").style.backgroundColor = "#d21e25";
    setStore_id('')
  };
  const ShowPayAtStore = (e) => {
    setPaymentMethod(1)
    document.querySelector("#payAtStore").style.display = "block";
    document.querySelector("#PayAtPlaceBtn").style.display = "none";
    document.querySelector("#payAtStoreBtn").style.backgroundColor = "#d21e25";
    document.querySelector("#ShowPayAtPlaceBtn").style.backgroundColor = "black";
  };
  // ========================================================================================
  function onImageChange(e) {
    console.log(e.target.files);
    setBTImage(e.target.files[0]);
    console.log('image' + btImg);
  }
  const proceedToSuccess = async (e) => {
    e.preventDefault();
      if (PaymentType === 2) {
        if (btImg === undefined || btImg == '') {
          props.changePopupText("Alert! Image is required!");
          return;
        } else {
          // alert(btImg)
          // return;
        }
      }
    const checkoutData = new FormData(e.target);
    const calculatedData = JSON.parse(
      localStorage.getItem("supreme_tech_calculations")
    );
    // console.log('block'+e.target);
    checkoutData.append("phone", localStorage.getItem("supremetech_phone"));
    checkoutData.append("authentication", localStorage.getItem("supremetech_authentication"));
    checkoutData.append("txn_id", calculatedData.txn_id);
    checkoutData.append("token_id", token);
    // ********************* params to set ***************************
    // checkoutData.append("name", Name);
    // checkoutData.append("state", Statee);
    // checkoutData.append("city", City);
    // checkoutData.append("street_address", Address);
    // checkoutData.append("store_id", store_id);
    // checkoutData.append("contact", Phone);
    checkoutData.append("image", btImg);
    checkoutData.append("payment_type", PaymentType);
    // checkoutData.append("pincode", pincode);
    // console.log("store_id -----" + JSON.stringify(store_id));
    // console.log("checkout data -----" + JSON.stringify(checkoutData));
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/checkout",
        checkoutData
      );
      // alert(JSON.parse(response));
      console.log("placed:", response, response.data.status);
      if (response.data.status === 200) {
        localStorage.setItem("supreme_tech_amount", response.data.amount);
        localStorage.setItem("supreme_tech_order_id", response.data.order_id);
        localStorage.setItem("supreme_tech_calculations", "");
        props.history.push("/place");
      } else if (response.data.message == 'Your account is inactive! Please contact to admin') {
        props.changePopupText(response.data.message);
      } else {
        // alert("fail");
        props.history.push("/fail");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [contact, setContact] = useState(prevAddress ? prevAddress.phone : "")
  const [pincode, setPincode] = useState(prevAddress ? prevAddress.pincode : "")
  const validateCheckoutForm = (e) => {
    const reg = /^\d+$/;
    if (e.target.name == 'phone') {
      if (reg.test(e.target.value) || e.target.value === "") {
        setPhone(e.target.value);
      } else {
        return false;
      }
    } else if (e.target.name == 'pincode') {
      if (reg.test(e.target.value) || e.target.value === "") {
        setPincode(e.target.value);
      } else {
        return false;
      }
    }
  };
  const [CartData, setVD] = useState("")
  const getViewData = async () => {
    var getViewData = new FormData();
    getViewData.append("token_id", localStorage.getItem("user_token"));
    getViewData.append("phone", localStorage.getItem("supremetech_phone"));
    getViewData.append("authentication", localStorage.getItem("supremetech_authentication"));
    for (let [key, value] of getViewData) {
      console.log("View Cart ka data", key, ":", value);
    }
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/view_cart_data",
        getViewData
      );
      console.log("Get Cart total:", response.data);
      if (response.data.status === 200) {
        setVD(response.data.data);
        console.log("this is CartProdData", response.data.data)
      } else {
        setVD([])
      }
    } catch {
      console.log("Issue in getting cart data");
    }
  };
  if (
    !(
      localStorage.getItem("supremetech_authentication") === "" ||
      localStorage.getItem("supremetech_authentication") === null
    )
  ) {
    // *************************************************** return *****************************************************  
    return (
      <div className="mt-4 pt-2">
        {/* login start */}
        <section className=" mb-5 pt-lg-5 pb-5">
          {popUpMessage !== "" ? (
            <Toast
              className="popupboxInPromoCode"
              // style={{ top: "10rem",zIndex: "500",left: "3rem",fontSize: "1.2rem",position: "fixed" }}
              show={showPopUp}
              onClose={toggleShowPopUp}
            >
              <Toast.Header style={{ background: "#de542d", color: "white" }}>
                <span>{popUpMessage}</span>
              </Toast.Header>
            </Toast>
          ) : (
            ""
          )}
          <div className="container-fluid">
            <div className="row p-1">
              {/* ===================================================== order summery ========================================             */}
              <div className="col-md-5 ml-auto mt-5 mt-lg-0" style={{ position: 'sticky-top', top: "0" }}>
                <table className="cart_table side_cart">
                  <tbody>
                    <tr style={{ background: "#d5d9d8", textAlign: 'center' }} class="not_show">
                    <th className="p-2">
                      Summary
                      </th>
                      <th>Name</th>
                      <th>Qty</th>
                      <th>Price</th>
                    </tr>
                    {CartData ? CartData.map((x) => {
                      return (
                        <tr style={{ textAlign: 'center' }}>
                          <td><img className="pt-3 pb-3" src={x.product_image} /></td>
                          <td style={{ fontSize: 11 }} >{x.product_name}</td>
                          <td>{x.quantity}</td>
                          <td>₹{x.price}</td>
                        </tr>
                      );
                    })
                      : ""}
                  </tbody>
                </table>
              </div>
              {/* ========================================= payment option ===============================              */}
              <div className="col-md-7 checkout_colm">
                <div className="hidden-menu1" style={{ position: 'sticky', top: '190px' }}>
                  <table className="cart_table side_cart">
                    <tbody>
                      <tr>
                        <td>Total</td>
                        <td>
                          <span>
                            <i className="fa fa-inr"></i>{" "}
                          </span>
                          {total}
                        </td>
                      </tr>
                      {promocodeDiscount ?
                        <tr>
                          <td>Discount</td>
                          <td>
                            <span>
                              <i className="fa fa-inr"></i>{" "}
                            </span>
                            {promocodeDiscount}
                          </td>
                        </tr>
                        : null}
                      <tr>
                        <td>Sub Total</td>
                        <td>
                          <span>
                            <i className="fa fa-inr"></i>{" "}
                          </span>
                          {subTotal}
                        </td>
                      </tr>
                      <tr>
                      </tr>
                    </tbody>
                  </table>
                  <label style={{ color: "#d21e25" }}>NOTE : Freight will be additional</label>
                  {PromoName ?
                    <div className="row ml-2">
                      <span>{PromoName}</span>
                      <img
                        style={{ width: '3%', height: '3%', marginLeft: '10px', marginTop: '5px' }}
                        src="img/cut.png"
                        onClick={() => RemovePromo()}
                      />
                    </div> : null}
                  <div className="row mb-4">
                    <div class="col-7">
                      <input type="text" name='promocode' placeholder="Promocode" class="form-control" onChange={(e) => setPromo(e.target.value)}></input>
                    </div>
                    <div class="col-5">
                      <button
                        className="btn c_new"
                        type="button"
                        id="ApplyPromo"
                        style={{ borderRadius: '0' }}
                        onClick={() => ApplyPromo()}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <form
                    className="d-block"
                    onSubmit={(e) => proceedToSuccess(e)}
                  >
                    {/* <div className="d-flex">
                      <div>
                        <button
                          className="btn c_new3"
                          type="button"
                          id="payAtStoreBtn"
                          style={{ borderRadius: '0', backgroundColor: "#d21e25" }}
                          onClick={(e) => ShowPayAtStore(e)}
                        >
                          Pay At Store
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn c_new3"
                          type="button"
                          id="ShowPayAtPlaceBtn"
                          style={{ borderRadius: '0', backgroundColor: "black" }}
                          onClick={(e) => ShowPayAtPlace(e)}
                        >
                          Pay At Place
                        </button>
                      </div>
                    </div> */}
                    {/* <div id="payAtStore" className="hidden-menu mt-3 border p-2">
                      <h6 className="text-left">
                        Motoconcept Stores
                      </h6>
                      {storeDetails
                        ? storeDetails.map((elem, i) => {
                          return (
                            <div onClick={(e) => payingThroughStore(e, elem)} >
                              <a
                                className="dropdown-item  c_new5 p-2 border" id={'store_' + elem.id}
                                href="#"
                              >
                                <p className='mb-0'>{elem.name}</p>
                                <p className='mb-0' style={{whiteSpace:"pre-wrap"}}>
                                  {elem.address +  
                                    " - " + 
                                    elem.pincode}
                                </p>
                                <p className='mb-0' >{elem.contact1}</p>
                                <p className='mb-0'>{elem.contact2}</p>
                              </a>
                            </div>
                          );
                        })
                        : null}
                    </div> */}
                    <div id="PayAtPlaceBtn" className="hidden-menu  mt-3 border" >
                      <form className="d-block mt-1 p-2">
                        {/* <div className="row">
                          <div className="col-6">
                            <label className="d-block">Full Name <sub>*</sub></label>
                            <input type="text" name="name" placeholder="" required onChange={(e) => setName(e.target.value)} />
                          </div>
                          <div className="col-6">
                            <label className="d-block">
                              City <sub>*</sub>
                            </label>
                            <input type="text" placeholder="" onChange={(e) => setCity(e.target.value)} />
                          </div>
                          <div className="col-6">
                            <label className="d-block">State <sub>*</sub></label>
                            <select
                              name="state"
                              class="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) => setState(e.target.value)}
                              required
                            >
                              <option disabled selected>
                                ---------Select State-------
                              </option>
                              {States.map((elem, i) => {
                                return <option value={elem.state_id}>{elem.state}</option>;
                              })}
                            </select>
                          </div>
                          <div className="col-6">
                            <label className="d-block">
                              Phone Number <sub>*</sub>
                            </label>
                            <input type="text" maxLength="10" name="phone" minLength="6" value={Phone} placeholder=""
                              onChange={(e) => validateCheckoutForm(e)} />
                          </div>
                          <div className="col-6">
                            <label className="d-block">Street Address <sub>*</sub></label>
                            <textarea
                              onChange={(e) => setAddress(e.target.value)}
                              cols={50}
                              rows={4}
                              placeholder=""
                            />
                          </div>
                          <div className="col-6">
                            <label className="d-block">
                              Pincode <sub>*</sub>
                            </label>
                            <input type="text" maxLength="6" minLength="6" name="pincode" value={pincode} placeholder=""
                              onChange={(e) => validateCheckoutForm(e)} />
                          </div>
                        </div> */}
                        <div className="row justify-content-center">
                          <div className="col-md-6">
                            <input
                              type="radio"
                              defaultValue={1}
                              name="payment_type"
                              id="cod"
                              defaultChecked
                              onClick={(e) => radioHandler(1)}
                            />
                            <label
                              style={{
                                paddingLeft: 10,
                                color: "#6f6f6f",
                                fontWeight: 600,
                                fontSize: 16,
                                lineHeight: 5,
                              }}
                              htmlFor="cod"
                            >
                              COD
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="radio"
                              defaultValue={2}
                              name="payment_type"
                              id="bank"
                              onClick={(e) => radioHandler(2)}
                            />
                            <label
                              style={{
                                paddingLeft: 10,
                                color: "#6f6f6f",
                                fontWeight: 600,
                                fontSize: 16,
                                lineHeight: 5,
                              }}
                              htmlFor="bank"
                            >
                              Bank Transfer
                            </label>
                          </div>
                        </div>
                        <div id="banktranfer" style={{ display: "none" }}>
                          <div className="row">
                            <div className="col-md-4">
                              <img src="/img/qp_code.jpg" alt="QR Code" style={{ position: "relative" }} />
                            </div>
                            <div className="col-md-8 text-center">
                              <p > NAME - MOTOCONCEPT <br />BANK - IDBI BANK<br />A/C NO - 10091814248<br />IFSC - IDFB0042128 <br />BRANCH - VAISHALI NAGAR, JAIPUR
                                <p style={{ fontWeight: "bold", fontSize: "1.5rem", marginLeft: '8px' }}></p>
                              </p>
                            </div>
                          </div>
                          <label className="btn p-1" for="btd_img">
                            Upload Transfer Details <sub>*</sub>
                          </label>
                          <input
                            className="form-control"
                            id="btd_img"
                            onChange={onImageChange}
                            placeholder="Upload"
                            name="image"
                            type="file"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="mt-5">
                      <input
                        type="submit"
                        className="btn  p-2 c_btn2"
                        style={{ borderRadius: '0' }}
                        name="submit"
                        value="Place Order"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <Redirect to="/register" />;
  }
}
export default Checkout;
