import axios from 'axios';
import React from "react";
import { Link } from 'react-router-dom'
import { baseUrl } from './baseUrl';
function Footer(props) {
  const d = new Date();
  let year = d.getFullYear();
  const subscribe = async () => {
    const subscriptionemail = document.getElementById("subscriptionemail").value;
    const subscribeData = new FormData();
    subscribeData.append("email", subscriptionemail)
    try {
      const response = await axios.post(baseUrl + "Apicontroller/subscribe_us", subscribeData);
      console.log("Subscribe: ", response)
      if (response.data.status === 200) {
        props.changePopupText("Your email is subscribe.")
      } else {
        props.changePopupText(response.data.message);
        // document.getElementById("subscriptionemail").value="";
      }
    } catch {
      console.log("Issue in subscribe api")
    }
  }
  return (
    <div>
      {/* footer start */}
      <footer className="mt-5 footer">
        <div className="container">
          {/* <div className="row ">
          <div className="col-md-6">
            <h4>
              <b>Sign up to receive our monthly newsletter</b>
            </h4>
            <div className="mt-2 mb-5 d-flex align-items-center">
              <input
                type="email"
                name
                placeholder="Enter your email address"
                id="subscriptionemail"
              />
              <input type="button" className="p-1"  style={{background: "#d7314a", color: "white", width:"30%"}}  onClick={()=> subscribe()} value="Sign up" />
            </div>
          </div>
        </div> */}
          <div className="row">
            <div className="col-md-3">
              <h5>INFORMATION</h5>
              <ul className="pl-0">
                <li>
                  <Link to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy and Policy</Link>
                </li>
                <li>
                  <Link to="/term">Terms & Condition</Link>
                </li>
                <li>
                  <Link to="/contactus">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 text-white">
              <div className="footer">
                <h3 className="footer-header">Bank Details</h3>
                <div style={{ width: '100%', marginTop: "-1em" }}>
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', fontSize: "13px", }}>NAME - MOTOCONCEPT&nbsp;</div>
                    <div style={{ width: '100%', fontSize: "13px", }}>BANK - IDFC BANK</div>
                    <div style={{ width: '100%', fontSize: "13px", }}>A/C NO - 10091814248</div>
                    <div style={{ width: '100%', fontSize: "13px", }}>IFSC - IDFB0042128</div>
                    <div style={{ width: '100%', fontSize: "13px", }}>BRANCH - VAISHALI NAGAR, JAIPUR</div>
                  </div>
                  <div class="mt-1">
                    <img src="/img/qp_code.jpg" alt="QR Code" style={{ position: "relative", width: "50%" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 text-white">
              <div className="footer">
                <div className="footer-header">
                  <h5>MY ACCOUNT</h5>
                  <ul className="pl-0">
                    {props.authentication === "" ?
                      <li>
                        <Link to="/signup">
                          Register Now
                        </Link>
                      </li>
                      :
                      <li>
                        <Link to="/profile">
                          My Profile
                        </Link>
                      </li>}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 text-white">
              <div className="footer">
                <div className="footer-header">
                  <h5 >Contact Us</h5>
                  <h5>MOTOCONCEPT</h5>
                  <p>Address,
                    <br />#11- Basement, Major Bane Singh Colony, Chand Bihari Nagar, Khatipura, Jaipur 302012 (Raj.)
                    <br />
                  </p>
                  Contact:
                  <p> +91-9928835119, +91-8302246482</p>
                  <p>Email: motoconceptjpr@gmail.com</p>
                  <p />
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12 text-center ">
              <div className="payment-methods">
                <p className="mb-0">© Copyright {year} MOTOCONCEPT</p>
              </div>
            </div>
            {/* <div className="col-md-9 d-flex text-white align-items-center justify-content-lg-end">
              Developed by{" "}
              <a href="https://www.fineoutput.com" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://fineoutput.com/assets/frontend/assets/img/logo.png"
                  alt="fineoutput"
                  width="100"
                  height="35"
                />
              </a>
            </div> */}
          </div>
        </div>
      </footer>
      {/* footer end */}
    </div>
  )
}
export default Footer
