import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
export default function ProductCard(props) {
  const [authentication, setAuthentication] = useState("");
  useEffect(() => checkAndSetCredentials(), []);
  const checkAndSetCredentials = () => {
    // Getting phone, authentication and token from local storage
    const user_phone = localStorage.getItem("supremetech_phone");
    const user_authentication = localStorage.getItem(
      "supremetech_authentication"
    );
    // If login/Registered
    if (user_phone && user_authentication) {
      setAuthentication(user_authentication);
    }
  }
  if (props.isSlider === "true") {
    return (
      <>
        <div key={props.product_id} className="p-2 col-md-12 col-12 col-sm-12">
          <div className="bor_ra text-left">
            <Link
              to={{
                pathname: `/product-details/${props.product_id}`,
                state: props.product_id,
              }}
            >
              <div style={{ position: 'relative' }}>
                {props.stock === 0 ?
                  <img
                    className="product-card-img img-fluid"
                    src={props.productimage}
                    alt={props.productname}
                    style={{  opacity: 0.5 }}
                  />
                  :
                  <img
                    className="product-card-img img-fluid"
                    src={props.productimage}
                    alt={props.productname}
                    style={{ }}
                  />
                }
                {props.stock === 0 ?
                  <div style={{
                    position: 'absolute',
                    color: 'white',
                    textAlign: 'center',
                    top: '50%',
                    right: '30%',
                    left: '30%',
                    background: "#d7314a",
                  }}>
                    <span>Out of Stock</span>
                  </div>
                  : null}
              </div>
            </Link>
            <p className="pro_name" style={{ minHeight: "48px" }}>
              {props.productname}
            </p>
            <div className="price_box mt-2">
              {authentication !== "" ?
                <div className="myDiv">
                  <p className="imMRP m-0" style={{ fontSize: '12px', color: "red",fontWeight:500  }}>
                    MRP : {" "}
                    <span style={{ textDecoration: "line-through", color: "red", }}>
                      <i className="fa fa-inr"></i>{props.mrp}
                    </span>
                  </p>
                  <p className="m-0" style={{ fontSize: '14px',fontWeight:500 }}>
                    Dealer Price :
                    <i className="fa fa-inr ml-2"></i>{props.price}
                  </p>
                </div>
                : null}
            </div>
            <div>
              <small>
                <button
                  className={"cart_btn c_btn2"}
                  onClick={() =>
                    props.addingToCartFunction(props.product_id, 1)
                  }
                >
                  ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
                </button>
              </small>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div key={props.product_id} class="col-lg-3 col-md-3 col-6 mb-4 p-2" >
          <div className="bor_ra">
            <Link
              to={{
                pathname: `/product-details/${props.product_id}`,
                state: props.product_id,
              }}
            >
              {props.stock === 0 ?
                <img
                  className="product-card-img img-fluid"
                  src={props.productimage}
                  alt={props.productname}
                  style={{ opacity: 0.5 }}
                />
                :
                <img
                  className="product-card-img img-fluid"
                  src={props.productimage}
                  alt={props.productname}
                  style={{ }}
                />
              }
              {props.stock === 0 ?
                <div style={{
                  position: 'absolute',
                  color: 'white',
                  textAlign: 'center',
                  top: '30%',
                  right: '30%',
                  left: '30%',
                  background: "#d7314a",
                }}>
                  <span>Out of Stock</span>
                </div>
                : null}
            </Link>
            <p className="pro_name" style={{ minHeight: "48px" }}>
              {props.productname}
            </p>
            {authentication !== "" ?
              <div className="myDiv">
                <p className="imMRP m-0" style={{ fontSize: '12px', color: "red",fontWeight:500 }}>
                  MRP : {" "}
                  <span style={{ textDecoration: "line-through", color: "red", }}>
                    <i className="fa fa-inr"></i>{props.mrp}
                  </span>
                </p>
                <p className="m-0" style={{ fontSize: '14px',fontWeight:500 }}>
                  Dealer Price :
                  <i className="fa fa-inr ml-2"></i>{props.price}
                </p>
              </div>
              : null}
            <div>
              <small>
                <button
                  className={"cart_btn c_btn2"}
                  onClick={() =>
                    props.addingToCartFunction(props.product_id, 1)
                  }
                >
                  ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
                </button>
              </small>
            </div>
          </div>
        </div>
      </>
    );
  }
}