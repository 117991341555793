import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import ProductCard from "./ProductCard";
function ShopByCar(props) {
  const [shopProduct, setShopProduct] = useState([]);
  const [brands, setBrands] = useState([]);
  const [heading, setHeading] = useState(false);
  const [FilterName, setFN] = useState({ brand: [], model: [], });
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [Visible, setVisible] = useState(false);
  var ProductData;
  useEffect(() => {
    fetchShopProductData()
  }, [props.match.params.bId, props.match.params.mId])
  useEffect(() => {
    applyingFilterAPI()
  }, [model]);
  //===================== fetch product data ===============
  const fetchShopProductData = async () => {
    try {
      if (typeof props.match.params.bId != 'undefined') {
        if (typeof props.match.params.mId == 'undefined') {
          console.log('bid-------' + JSON.stringify(props.match.params.bId))
          ProductData = await axios(baseUrl + "Apicontroller/get_rel_products/" + props.match.params.bId);
        } else {
          console.log('bid-------' + JSON.stringify(props.match.params.bId))
          console.log('mid-------' + JSON.stringify(props.match.params.mId))
          ProductData = await axios(baseUrl + "Apicontroller/get_rel_products/" + props.match.params.bId + "/" + props.match.params.mId);
        }
      } else {
        ProductData = await axios(baseUrl + "Apicontroller/get_rel_products");
      }
      if (ProductData.data.status === 200) {
        console.log("shop by car products all pd:", JSON.stringify(ProductData.data.data));
        setHeading(ProductData.data.heading)
        setShopProduct(ProductData.data.data)
        getBrands();
      }
    } catch {
      console.log("Issue in get product detail.");
    }
  }
  //=========================== fetch filter data======================
  const getBrands = async () => {
    try {
      let response = await axios.get(baseUrl + "Apicontroller/get_brands");
      console.log("brands:", response.data);
      if (response.data.status === 200) {
        setBrands(response.data.data);
      }
    } catch {
      console.log("Issue in get category.");
    }
  };
  ///========================set filter ==================
  const setFilters = (e) => {
    // alert(e.target.checked);
    console.log("e k andar", e.target.attributes.filterid, e.target.id);
    const filterId = document
      .getElementById(e.target.id)
      .getAttribute("filterId");
    const filterName = document
      .getElementById(e.target.id)
      .getAttribute("filterName");
    if (filterName === "model") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (model === "") {
          setModel(filterId);
        } else {
          let newdata = model + "," + filterId;
          setModel(newdata);
        }
      } else {
        if (model !== "") {
          let prevArray = model.split(",");
          let index = model.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            model.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setModel(newdata);
        }
      }
    }
  };
  //====================== apply filter ===========================
  const applyingFilterAPI = async () => {
    // console.log("this is my brand", brand);
    if (
      model !== ""
    ) {
      // alert("hi")
      console.log("Applying filter api");
      const appliedFilterData = new FormData();
      appliedFilterData.append("model", model);
      for (let pair of appliedFilterData.entries()) {
        console.log(pair[0], ":", pair[1]);
      }
      try {
        const response = await axios.post(baseUrl + "Apicontroller/filter", appliedFilterData);
        console.log("filtered product data", response);
        if (response.data.status === 201) {
          setShopProduct([]);
          // setEmptyMessage("No product available.");
        } else {
          console.log("filtered product data", response.data.data);
          setHeading('Showing Filter Results')
          setShopProduct(response.data.data);
          // setEmptyMessage("");
        }
      } catch {
        console.log("Issue in get filterd data");
      }
    } else {
      fetchShopProductData();
    }
  };
  //================================show filter ===================
  const openFilter = () => {
    document.querySelector(".side_filter").style.transform = "translateX(0px)";
  }
  //================================close filter ===================
  const closeFilter = () => {
    document.querySelector(".side_filter").style.transform = "translateX(900px)";
  }
  return (
    <div>
      <section className="filter_img pt-5 mt-5">
        <div className="container-fluid px-5 mob-viewpaddding">
          <div className="row heading-div">
            <div className="col-md-12 text-center">
              {heading === 'All Car Products' ?
                <h1 id='heading' className="heading"> {heading}</h1>
                :
                <h1 id='heading' className="heading"> {heading} Accessories</h1>
              }
            </div>
          </div>
          {/* ===========================  mob view toogle filter ======================== */}
          <div className="d-block d-md-none">
            <div className="row d-flex text-right justify-content-end mb-2">
              <div className="col-5 py-2 d-flex text-right border justify-content-end" onClick={() => { openFilter() }}>
                <div><p className="mb-0">Filters</p></div>
                <div><i className="fa fa-filter px-2" style={{ fontSize: "1.4rem" }}></i></div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* {shopProduct.length > 0
              && */}
            <div className="col-md-3 col-12 p-2 border d-none d-md-block">
              {/* ===========================  web view filter ======================== */}
              {/* d-none d-md-block */}
              <div className="">
                <div className={'mb-2'}>
                  {/* <button className={'c_btn2'}
                    onClick={() => {
                      return applyingFilterAPI()
                    }}>Apply Filter</button> */}
                  <div className="text-center"><p className="mb-0" style={{ color: "black", fontWeight: "bold" }}>Filters</p></div>
                </div>
                <div className="accordion filter_shopbycar" id="filter_shopbycar">
                  {brands ? brands.map((x) => {
                    return (
                      <div className="card" >
                        <div className="card-header shopBycar" id={x.name}>
                          <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                            data-toggle="collapse"
                            data-target={"#collapse" + x.id}
                            aria-expanded="true"
                            aria-controls={"#collapse"}
                          >
                            <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                              {x.name}
                            </span>
                            <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                              <i className="fa fa-chevron-circle-down"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"collapse" + x.id}
                          className="collapse"
                          aria-labelledby={"heading"}
                          data-parent="#filter_shopbycar">
                          <>
                            <div className="card-body shopBycar_item">
                              <ul style={{ fontSize: "20px", }}
                                className="pl-0 mt-0 mb-0 mt-2">
                                {x ? x.car_model.map((elem, i) => {
                                  return (
                                    <li style={{ fontSize: "16px", transform: 'none' }} key={i}>
                                      <input type="checkbox" name="name" id={elem.id} className="CheckShopbycar"
                                        filterid={elem.id}
                                        filtername="model"
                                        onClick={(e) => setFilters(e)} />
                                      <label for={elem.id} className="mx-2 m-0">
                                        {elem.name}</label>
                                    </li>
                                  );
                                })
                                  : ""}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                    );
                  })
                    : ""}
                </div>
              </div>
            </div>
            {/* } */}
            <div className="col-md-9 col-12 p-0 px-2">
              {shopProduct.length > 0 ?
                <div className="row">
                  {shopProduct.length > 0 ?
                    shopProduct?.map((elem, i) => {
                      return (
                        <ProductCard
                          key={i}
                          product_id={elem.product_id}
                          productimage={elem.image ? elem.image : elem.product_image}
                          productname={elem.product_name}
                          mrp={elem.mrp ? elem.mrp : elem.MRP}
                          price={elem.price}
                          addingToCartFunction={props.addingToCart}
                          isSlider="false"
                        />
                      );
                    })
                    :
                    null
                  }
                </div>
                :
                <div className="w-100 text-center mt-5">
                  <h5>No Data Found!</h5>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
      {/*====================== apply filter mobile view =============================== */}
      <section className="side_filter d-block d-md-none" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span className="close_side d-flex text-white" style={{ fontSize: "1.5rem" }} onClick={() => { closeFilter() }}>X</span>
            </div>
            <div className="col-12 p-0 mt-5 pt-2">
              {/* <div className={'mb-2'}>
                <button className={'text-danger btn btn-light mx-2 font-weight-bold'}
                  onClick={() => {
                    return applyingFilterAPI(), closeFilter()
                  }}>Apply Filter</button>
              </div> */}
              <div className="accordion filter_shopbycar" id="filter_shopbycar">
                {brands ? brands.map((x) => {
                  return (
                    <div className="card">
                      <div className="card-header shopBycar" id={x.name}>
                        <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }} role="button"
                          data-toggle="collapse"
                          data-target={"#collapse" + x.id}
                          aria-expanded="true"
                          aria-controls={"#collapse"}
                        >
                          <span style={{ fontSize: "1rem", margin: "auto", marginLeft: "0px" }} className="d-block">
                            {x.name}
                          </span>
                          <button className="btn btn-link w-50 ml-auto text-end" type="button" style={{ textAlign: 'end' }}>
                            <i className="fa fa-chevron-circle-down"></i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id={"collapse" + x.id}
                        className="collapse"
                        aria-labelledby={"heading"}
                        data-parent="#filter_shopbycar">
                        <>
                          <div className="card-body shopBycar_item">
                            <ul style={{ fontSize: "20px", }}
                              className="pl-0 mt-0 mb-0 mt-2">
                              {x ? x.car_model.map((elem, i) => {
                                return (
                                  <li style={{ fontSize: "16px", transform: 'none' }} kye={i}>
                                    <input type="checkbox" name="name" id={elem.id} className="CheckShopbycar"
                                      filterid={elem.id}
                                      filtername="model"
                                      onClick={(e) => setFilters(e)} />
                                    <label for={elem.id} className="mx-2 m-0">
                                      {elem.name}</label>
                                  </li>
                                );
                              })
                                : ""}
                            </ul>
                          </div>
                        </>
                      </div>
                    </div>
                  );
                })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ShopByCar;
