import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import { Redirect } from "react-router";
import { Link } from 'react-router-dom'
function Register(props) {
  const [Districts, setDistricts] = useState(null);
  const [State, setState] = useState([]);
  const [City, setCity] = useState([]);
  const setDistrictsId = (e) => {
    // alert(e.target.value);
    setDistricts(e.target.value);
    getCity(e.target.value)
  };
  useEffect(() => {
    getState();
  }, []);
  const getState = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/all_state_get"
      );
      console.log("all_state_get", response);
      if (response.data.status === 200) {
        setState(response.data.data);
      }
    } catch {
      console.log("Issue in all_state_get.");
    }
  };
  const getCity = async (e) => {
    // alert('----state----'+e)
    try {
      let response = await axios(
        baseUrl + "Apicontroller/get_cities/" + e
      );
      console.log("get_cities", response);
      if (response.data.status === 200) {
        setCity(response.data.data);
      }
    } catch {
      console.log("Issue in get_cities.");
    }
  };
  const loginUser = async (e) => {
    e.preventDefault();
    const loginData = new FormData(e.target);
    try {
      const response = await axios.post(
        baseUrl + "Users/login",
        loginData
      );
      console.log("login", response.data);
      if (response.data.code === 201) {
        props.changePopupText(response.data.message);
        document.querySelector("#loginError").innerHTML = response.data.message;
      } else if (response.data.code === 200) {
        document.querySelector("#loginError").innerHTML = "Check for otp!";
        const loginphone = document.querySelector("#loginphone").value;
        // const loginname=document.querySelector("#loginname").value;
        localStorage.setItem("supremetech_phone", loginphone);
        // localStorage.setItem("supremetech_name", loginname)
        props.history.push("/login-otp");
        // localStorage.setItem("supremetech_phone", response.data.email);
        // localStorage.setItem("supremetech_name", response.data.user_name);
        // props.changePopupText("User successfully login");
        // props.checkAndSetCredentials();
      }
    } catch {
      console.log("Issue in login");
    }
  };
  const registerUser = async (e) => {
    e.preventDefault();
    const registerData = new FormData(e.target);
    registerData.append("token_id", localStorage.getItem("user_token"));
    try {
      const response = await axios.post(
        baseUrl + "Users/user_register",
        registerData
      );
      console.log("Register", response);
      // if(response.status===200 || response.code===200) {
      //   document.querySelector("#registerError").innerHTML="User successfully registered & login";
      if (response.data.status === 201 || response.data.code === 201) { 
        const violation = document.getElementById('registerError'); 
        window.scrollTo({
          top:violation.offsetTop,
          behavior:"smooth"
      });
        props.changePopupText(response.data.message);
        document.querySelector("#registerError").innerHTML ="<span style='color:red'> "+response.data.message+"</span>";
      } else if (response.data.status === 200 || response.data.code === 200) {
        document.querySelector("#registerError").innerHTML = "Check for otp!";
        const registeredphone =
          document.querySelector("#registeredphone").value;
        const registeredname = document.querySelector("#registeredname").value;
        const registeredadderess = document.querySelector(
          "#registeredadderess"
        ).value;
        // const registeredname=document.querySelector("#registeredadderess").value;
        localStorage.setItem("supremetech_phone", registeredphone);
        localStorage.setItem("supremetech_name", registeredname);
        localStorage.setItem("supremetech_address", registeredadderess);
        props.history.push("/register-otp");
        // localStorage.setItem("create_outdoor_email", response.data.email);
        // localStorage.setItem("create_outdoor_password", response.data.password);
        // localStorage.setItem("create_outdoor_name", response.data.user_name);
        // props.checkAndSetCredentials();
        // props.changePopupText("User successfully register & login");
      }
    } catch {
      console.log("Issue in register");
    }
  };
  const [contact, setContact] = useState('');
  const [zipcode, setZipcode] = useState('');
  const validateSignupForm = (e) => {
    if (e.target.name === "zipcode") {
      const reg = /^\d+$/;
      if (reg.test(e.target.value) === false) {
        if (e.target.value === '') {
          setZipcode('')
        }
      } else {
        setZipcode(e.target.value);
        // alert('hi')
      }
    } else if (e.target.name === "phone") {
      const reg = /^\d+$/;
      if (reg.test(e.target.value) || e.target.value === "") {
        setContact(e.target.value);
      }
    }
  };
  const [phone, setPhone] = useState("")
  const validateLoginForm = (e) => {
    const reg = /^\d+$/;
    if (reg.test(e.target.value) || e.target.value === "") {
      setPhone(e.target.value);
    }
  };
  // alert(localStorage.getItem("supremetech_authentication"))
  if (
    localStorage.getItem("supremetech_authentication") === "" ||
    localStorage.getItem("supremetech_authentication") === null
  ) {
    return (
      <div>
        <section className="mt-5 mb-5 pt-5 pb-5 login_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center mb-5">
                <h2 className="title">DEALER LOGIN/SIGNUP</h2>
              </div>
              <div className="col-md-6">
              <h6>EXISTING DEALER LOGIN</h6>
                <hr />
                <p id="loginError" className="font_monst gry_col mb-4" style={{ color: 'red' }}>
                </p>
                <form
                  id="loginform"
                  className="form"
                  onSubmit={(e) => loginUser(e)}
                >
                  <input
                    type="text"
                    name="phone"
                    id="loginphone"
                    placeholder="Enter Your Registered Mobile Number"
                    className="mb-4"
                    maxlength="10"
                    minlength="10"
                    value={phone}
                    required
                    onChange={(e) => validateLoginForm(e)}
                  />
                  <div className="d-flex mt-3 justify-content-between">
                    {/* <button style={{background:"#dc3545", color: "white"}} type="submit" className="btn knw_btn">
                    sign in
                  </button> */}
                    <button
                      style={{ background: "#dc3545", color: "white" }}
                      type="submit"
                      className="btn knw_btn active c_btn2"
                    >
                      Send OTP
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-6 mt-lg-0 mt-md-0 mt-5">
                <h6>NEW DEALER</h6>
                <hr />
                <p  id="registerError" className="font_monst gry_col mb-4">
                  Don’t have an account! Register Now
                </p>
                <form className="form" onSubmit={(e) => registerUser(e)}>
                  <div className="d-flex mb-2">
                    <input
                      type="text"
                      name="name"
                      id="registeredname"
                      placeholder="Full Name"
                      className="mb-4"
                      required
                    />
                    <input
                      type="email"
                      id="registeremail"
                      name="email"
                      placeholder="Email"
                      className="mb-4 text-center"
                      required
                    />
                    {/* <input
                      type="text"
                      name="dob"
                      placeholder="D.O.B (mm/dd/yyyy) "
                      className="text-center"
                      value={date}
                      onChange={(e) => validateDate(e)}
                      required
                    /> */}
                  </div>
                  <div className="d-flex mb-2">
                    <input
                      type="text"
                      name="phone"
                      id="registeredphone"
                      placeholder="Phone Number"
                      className="mb-4"
                      value={contact}
                      onChange={(e) => validateSignupForm(e)}
                      maxlength="10"
                      minlength="10"
                      required
                    />
                    <input
                      type="text"
                      name="address"
                      id="registeredadderess"
                      placeholder="Address"
                      className="mb-4"
                      required
                    />
                  </div>
                  <div className="d-flex mb-2">
                    <select
                      name="state"
                      class="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => setDistrictsId(e)}
                      required
                    >
                      <option disabled selected>
                        ---------Select State-------
                      </option>
                      {State.map((elem, i) => {
                        return <option value={elem.state_id}>{elem.state}</option>;
                      })}
                    </select>
                    <select
                      name="district"
                      class="form-control text-center"
                      id="exampleFormControlSelect1"
                      required
                    >
                      <option disabled selected>
                        ---------Select District-------
                      </option>
                      {City
                        ? City.map((elem) => {
                          return <option value={elem.id} >{elem.name}</option>;
                        })
                        : null}
                    </select>
                  </div>
                  <div className="d-flex mb-2">
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      className="mb-4"
                      required
                    />
                    <input
                      type="text"
                      name="zipcode"
                      placeholder="Zip/Postalcode"
                      className="mb-4"
                      value={zipcode}
                      onChange={(e) => validateSignupForm(e)}
                      maxLength="6"
                      required
                    />
                  </div>
                  <div className="d-flex mb-2">
                    <input
                      type="text"
                      name="company_name"
                      placeholder="YOUR COMPANY / FIRM NAME"
                      className="mb-4"
                      required
                    />
                    <input
                      type="text"
                      name="gstin"
                      placeholder="GSTIN(Optional)"
                      className="mb-4"
                    />
                  </div>
                  {/*  *************************ADDED FIELDS ******************************** */}
                  {/* <div className="d-flex mb-2">
                  <input
                      type="text"
                      name="city"
                      placeholder="City"
                      className="mb-4"
                      required
                    />
                    <input
                      type="text"
                      name="house_no"
                      placeholder="House No."
                      className="mb-4"
                      required
                    />
                  </div> */}
                  {/*  *************************ADDED FIELDS ******************************** */}
                  <div className="d-flex mb-2">
                    <label>Upload Business Card</label>
                    <input
                      type="file"
                      name="image1"
                      id="vc1"
                      placeholder="Visit Card field *"
                      className="p-0 h-100"
                      required
                    />
                    {/* <input
                      type="file"
                      id="vc2"
                      name="image2"
                      placeholder="Visit Card field"
                      className="p-0 h-100"
                    /> */}
                  </div>
                  {/* <input
                    type="file"
                    name="image2"
                    placeholder="No file chosen"
                    className="p-0 justify-content-between"
                  /> */}
                  <div className="d-flex mb-2 ">
                    <input type="checkbox" id="condition" required name="condition" value="1" style={{ width: '5%' }} />
                    <label className="mt-2 ml-2"><span style={{ color: '#d21e25', fontWeight: '500' }}><Link to="/term"> Terms & Conditions </Link></span> And <span style={{ color: '#d21e25', fontWeight: '500' }}> <Link to="/privacy">Privacy Policy </Link></span></label>
                  </div>
                  <button
                    style={{ background: "#dc3545", color: "white" }}
                    type="submit"
                    className="btn knw_btn mt-3 c_btn2"
                  >
                    Create an account
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}
export default Register;
