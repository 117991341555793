import React from 'react'
function Contactus() {
  return (
    <div>
      <section>
        <div className="map mt-5">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14228.234156877823!2d75.7483952!3d26.9333586!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0e0abb3e47adb01!2sMotoConcept!5e0!3m2!1sen!2sin!4v1660644618934!5m2!1sen!2sin" width="100%" height="500px" style={{ border: 0, marginTop: '2.2rem' }} allowFullScreen loading="lazy" />
        </div>
      </section>
      <section className="pt-50 client-main align-center">
        <div className="container">
          <div className="contact-info">
            <div className="row m-0">
              <div className="col-sm-4 p-0">
                <div className="contact-box change">
                  <div className="contact-icon contact-phone-icon"><i className="fa fa-phone" /></div>
                  <span><b>Contatct Number</b></span>
                  <p>+91-9928835119, +91-8302246482</p>
                </div>
              </div>
              <div className="col-sm-4 p-0">
                <div className="contact-box change">
                  <div className="contact-icon contact-mail-icon"><i className="fa fa-envelope-open" /></div>
                  <span><b>E-mail Address</b></span>
                  <p><a href="mailto:motoconceptjpr@gmail.com">motoconceptjpr@gmail.com</a> </p>
                </div>
              </div>
              <div className="col-sm-4 p-0">
                <div className="contact-box change">
                  <div className="contact-icon contact-open-icon"><i className="fa fa-map-marker" /></div>
                  <span><b>Address</b></span>
                  <p>#11- Basement, Major Bane Singh Colony, Chand Bihari Nagar, Khatipura, Jaipur 302012 (Raj.)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Contactus
