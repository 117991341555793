import React from "react";

const ProductDetailsSlider = ({ productImages, productVideos}) => {
  // productImages=productImages]

  return (
    <div className="mt-4 pt-md-5 pt-lg-5 pt-sm-5 mt-sm-5">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div
            id="custCarousel"
            className="carousel slide"
            data-ride="carousel"
            align="center"
          >
            {/* <!-- slides --> */}
            <div className="carousel-inner">
              {productImages
                ? productImages.map((elem, i) => {
                  if (i === 0) {
                    return (
                      <div class="carousel-item active" >
                        {" "}
                        <img  className="slider-img" 
                        src={elem} alt="" 
                          />
                          {" "}
                      </div>
                    );
                  } else {
                    return (
                      <div class="carousel-item">
                        {" "}
                        <img className="slider-img"
                        src={elem} alt=""   />{" "}
                      </div>
                    );
                  }
                  })
                : null}
                {productVideos
                ? productVideos.map((elem, i) => {
                  if(elem != ""){
                    return (
                      <div class="carousel-item">
                        {" "}
                        <video className="slider-img"
                         controls loop width="100%" height="auto">
                        <source src={elem} alt="video" />{" "}
                      </video>
                          
                      </div>
                    );}
                  })
                : null}

            </div>
            {/* <!-- Left right -->  */}
            <a
              className="carousel-control-prev"
              href="#custCarousel"
              data-slide="prev"
            >
              {" "}
              <span className="carousel-control-prev-icon"></span>{" "}
            </a>{" "}
            <a
              className="carousel-control-next"
              href="#custCarousel"
              data-slide="next"
            >
              {" "}
              <span className="carousel-control-next-icon"></span>{" "}
            </a>
            {/* <!-- Thumbnails --> */}
            <ol className="carousel-indicators list-inline">
              {productImages
                ? productImages.map((elem, i) => {
                  if (i === 0) {
                    return (
                      <li class="list-inline-item active">
                        {" "}
                        <a
                          id="carousel-selector-0"
                          class="selected"
                          data-slide-to={i}
                          data-target="#custCarousel"
                        >
                          {" "}
                          <img src={elem} class="img-fluid" />{" "}
                        </a>{" "}
                      </li>
                    );
                  } else {
                    return (
                      <li class="list-inline-item">
                        {" "}
                        <a
                          id="carousel-selector-1"
                          data-slide-to={i}
                          data-target="#custCarousel"
                        >
                          {" "}
                          <img src={elem} class="img-fluid" />{" "}
                        </a>{" "}
                      </li>
                    );
                  }
                  })
                : null}
                {productVideos
                ? productVideos.map((elem, i) => {
                    let id = parseInt(i)+2
                    if(elem != ""){
                      return (
                        <li class="list-inline-item ">
                          {" "}
                          <a
                            id="carousel-selector-1"
                            data-slide-to={id}
                            data-target="#custCarousel"
                          >
                             <img src='../img/play.png' class="img-fluid" />
                           </a>{" "}
                        </li>
                      );}
                    
                  })
                : null}

            </ol>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default ProductDetailsSlider;