import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import { Redirect } from "react-router";
export default function LoginOTP(props) {
  const [responeMessage, setResponseMessage] = useState(
    "Please insert the OTP sent on your registered mobile number"
  );
  const sendLoginOTP = async (e) => {
    e.preventDefault();
    const emailData = new FormData(e.target);
    emailData.append("phone", localStorage.getItem("supremetech_phone"));
    try {
      const response = await axios.post(
        baseUrl + "Users/login_otp_verify",
        emailData
      );
      console.log("otp register:", response);
      if (response.data.status === 201) {
        setResponseMessage(response.data.message);
      } else {
        localStorage.setItem("supremetech_authentication", response.data.authentication)
        localStorage.setItem("supremetech_name", response.data.name)
        localStorage.setItem("email", response.data.email)
        localStorage.setItem("company_name", response.data.company_name)
        setResponseMessage("User successfully login.");
        props.checkAndSetCredentials()
        props.changePopupText("User successfully login.")
      }
    } catch {
      console.log("Issue in login otp");
    }
  };
  const [Otp, setOtp] = useState("")
  const validateForm = (e) => {
    const reg = /^\d+$/;
    if (reg.test(e.target.value) || e.target.value === "") {
      setOtp(e.target.value);
    } else {
      return false;
    }
  };
  if (
    !localStorage.getItem("supremetech_phone") ||
    !localStorage.getItem("supremetech_authentication")
  ) {
    return (
      <section className="mt-5 mb-5 pt-5 pb-5 login_sec">
        <div className="row heading-div">
          <div className="col-md-12 text-center">
            <h1 className="heading">OTP Verification</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-10 col-md-4">
            <p
              id="checkoutError"
              className="alert alert-secondary alert-dismissible fade show mb-4"
            >
              {responeMessage}
            </p>
            <form className="form-block-holder" onSubmit={(e) => sendLoginOTP(e)}>
              <div className="form-group">
                <label for="otp">OTP</label>
                <input value={Otp}
                  maxLength="6"
                  minLength="6"
                  required
                  onChange={(e) => validateForm(e)} name="otp" className="form-control" />
              </div>
              <div className="form-group">
                <input type="submit" className="btn w-50 c_btn2" />
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  } else {
    return <Redirect to="/" />;
  }
}
